import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useQueryClient } from "react-query";
import type { RouteObject } from "react-router-dom";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { constants, isDev } from "config";
import {
  SignIn,
  requireAuthentication,
  requireAuthorization,
  useAuth,
  useWalletAuth,
} from "features/auth";
import {
  LayoutFull,
  LayoutSite,
  LayoutTask,
  LayoutTaskBlank,
} from "features/layouts";
import { requireShopBuilderAccess } from "features/reviewable";
import { scrollToTop, asyncScrollToHash } from "utils/scroll";
import { importRetry } from "utils/routing";
import { usePageTracking } from "contexts/mixpanel";
import { defaultMetaData } from "features/system";

// Lazy imports for code-splitting based on routes, alphabetized by name
const About = React.lazy(() =>
  importRetry(() => import(/* webpackChunkName: "About" */ "./pages/About")),
);
const AdminCreatePost = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminCreatePost" */ "./pages/AdminCreatePost"
      ),
  ),
);
const AdminCreateUser = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminCreateUser" */ "./pages/AdminCreateUser"
      ),
  ),
);
const AboutArtistProfile = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileBenefits" */ "./pages/ArtistProfileBenefits"
      ),
  ),
);
const AdminHomePage = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "AdminHomePage" */ "./pages/AdminHomePage"),
  ),
);
const AdminHomePageV2Publish = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminHomePagePublish" */ "./pages/AdminHomePageV2Publish"
      ),
  ),
);
const AdminHUGPointGrant = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminHUGPointGrant" */ "./pages/AdminHUGPointGrant"
      ),
  ),
);
const AdminImageModeration = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminImageModeration" */ "./pages/AdminImageModeration"
      ),
  ),
);
const AdminUserDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminUserDetails" */ "./pages/AdminUserDetails"
      ),
  ),
);
const AdminWrapper = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminWrapper" */ "./features/admin/components/AdminWrapper"
      ),
  ),
);
const AllNotifications = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AllNotifications" */ "./features/notifications/components/AllNotifications"
      ),
  ),
);
const ArtistAdmin = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "EditArtist" */ "./pages/ArtistAdmin"),
  ),
);
const ArtistApplication = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistApplication" */ "./pages/ArtistApplication"
      ),
  ),
);
const ArtistApplications = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistApplications" */ "./pages/ArtistApplications"
      ),
  ),
);
const ArtistCreatePost = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistCreatePost" */ "./pages/ArtistCreatePost"
      ),
  ),
);
const ArtistProfile = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "ArtistProfile" */ "./pages/ArtistProfile"),
  ),
);
const ArtistFollowersPage = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistFollowersPage" */ "./pages/ArtistFollowersPage"
      ),
  ),
);
const ArtistInvites = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "ArtistInvites" */ "./pages/ArtistInvites"),
  ),
);
const ArtistPost = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "ArtistPost" */ "./pages/ArtistPost"),
  ),
);
const ArtistPostEdit = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "ArtistPostEdit" */ "./pages/ArtistPostEdit"),
  ),
);
const ArtistProfileAbout = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileAbout" */ "./pages/ArtistProfileAbout"
      ),
  ),
);
const ArtistProfileEditDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileEditDetails" */ "./pages/ArtistProfileEditDetails"
      ),
  ),
);
const ArtistProfileFeaturedEdit = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileFeaturedEdit" */ "./pages/ArtistProfileFeaturedEdit"
      ),
  ),
);
const ArtistProfileGalleryEdit = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileGalleryEdit" */ "./pages/ArtistProfileGalleryEdit"
      ),
  ),
);
const ArtistProfileGalleryItemEdit = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileGalleryItemEdit" */ "./pages/ArtistProfileGalleryItemEdit"
      ),
  ),
);
const ArtistProfileOverview = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileOverview" */ "./pages/ArtistProfileOverview"
      ),
  ),
);
const ArtistProfilePortfolio = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfilePortfolio" */ "./pages/ArtistProfilePortfolio"
      ),
  ),
);
const ArtistProfilePortfolioEdit = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfilePortfolioEdit" */ "./pages/ArtistProfilePortfolioEdit"
      ),
  ),
);
const ArtistProfilePortfolioGallery = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfilePortfolioGallery" */ "./pages/ArtistProfilePortfolioGallery"
      ),
  ),
);
const ArtistProfilePortfolioItem = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfilePortfolioItem" */ "./pages/ArtistProfilePortfolioItem"
      ),
  ),
);
const ArtistProfilePortfolioLayout = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfilePortfolioLayout" */ "./pages/ArtistProfilePortfolioLayout"
      ),
  ),
);
const ArtistProfilePortfolioReorder = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfilePortfolioReorder" */ "./pages/ArtistProfilePortfolioReorder"
      ),
  ),
);
const ArtistProfileReviews = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileReviews" */ "./pages/ArtistProfileReviews"
      ),
  ),
);
const ArtistShop = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "ArtistShop" */ "./pages/ArtistShop"),
  ),
);
const ArtistShopEditWrapper = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistShopEditWrapper" */ "./pages/ArtistShopEditWrapper"
      ),
  ),
);
const ArtistShopEditor = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistShopEditor" */ "./pages/ArtistShopEditor"
      ),
  ),
);
const ArtistShopOrders = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistShopOrders" */ "./pages/ArtistShopOrders"
      ),
  ),
);
const ArtistShopEditInventory = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistShopEditInventory" */ "./pages/ArtistShopEditInventory"
      ),
  ),
);
const ProductBuilderDisabled = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ProductBuilderDisabled" */ "./pages/ProductBuilderDisabled"
      ),
  ),
);
const ArtistShopProduct = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistShopProduct" */ "./pages/ArtistShopProduct"
      ),
  ),
);
const AdminArtistAccess = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminArtistAccess" */ "./pages/AdminArtistAccess"
      ),
  ),
);
const AdminShopOrders = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminShopOrders" */ "./pages/AdminShopOrders"
      ),
  ),
);
const AdminShopProducts = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminShopProducts" */ "./pages/AdminShopProducts"
      ),
  ),
);
const AdminShopTaxDocuments = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AdminShopTaxDocuments" */ "./pages/AdminShopTaxDocuments"
      ),
  ),
);
const ArtistShopProductCreate = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistShopProductCreate" */ "./pages/ArtistShopProductCreate"
      ),
  ),
);
const ArtistShopProductWizard = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistShopProductWizard" */ "./pages/ArtistShopProductWizard"
      ),
  ),
);
const ArtistProfileUpdates = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ArtistProfileUpdates" */ "./pages/ArtistProfileUpdates"
      ),
  ),
);
const ArtistVoting = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "ArtistVoting" */ "./pages/ArtistVoting"),
  ),
);
const BannerMaker = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "BannerMaker" */ "./pages/BannerMaker"),
  ),
);
const Checkout = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "Checkout" */ "./pages/Checkout"),
  ),
);
const CheckoutConfirmation = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CheckoutConfirmation" */ "./pages/CheckoutConfirmation"
      ),
  ),
);
const ClaimArtistProfile = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ClaimArtistProfile" */ "./features/reviewable/components/ClaimArtistProfile"
      ),
  ),
);
const CollectiveAllPosts = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CollectiveAllPosts" */ "./features/posts/components/CollectiveAllPosts"
      ),
  ),
);
const CollectiveAdminCreate = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CollectiveAdminCreate" */ "./pages/CollectiveAdminCreate"
      ),
  ),
);
const CollectiveAdminDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CollectiveAdminDetails" */ "./pages/CollectiveAdminDetails"
      ),
  ),
);
const CollectiveAdminEdit = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CollectiveAdminEdit" */ "./pages/CollectiveAdminEdit"
      ),
  ),
);
const CollectiveAdminList = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CollectiveAdminList" */ "./pages/CollectiveAdminList"
      ),
  ),
);
const CollectiveDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CollectiveDetails" */ "./pages/CollectiveDetails"
      ),
  ),
);
const CollectiveList = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "CollectiveList" */ "./pages/CollectiveList"),
  ),
);
const CollectiveEdit = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "CollectiveList" */ "./pages/CollectiveEdit"),
  ),
);
const CreateAccountEmail = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CreateAccountEmail" */ "./pages/CreateAccountEmail"
      ),
  ),
);
const CreateArtist = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "CreateArtist" */ "./pages/CreateArtist"),
  ),
);
const CreateArtistApplication = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CreateArtistApplication" */ "./pages/CreateArtistApplication"
      ),
  ),
);
const CreateArtistReview = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CreateArtistReview" */ "./pages/CreateArtistReview"
      ),
  ),
);
const CuratorReport = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "CuratorReport" */ "./features/admin/components/CuratorReport"
      ),
  ),
);
const CurationCorner = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "CurationCorner" */ "./pages/CurationCorner"),
  ),
);
const DeiInitiatives = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "DeiInitiatives" */ "./pages/DeiInitiatives"),
  ),
);
const Dev = React.lazy(() =>
  importRetry(() => import(/* webpackChunkName: "Dev" */ "./pages/Dev")),
);
const EditArtist = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "EditArtist" */ "./pages/EditArtist"),
  ),
);
const EditArtistApplication = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "EditArtistApplication" */ "./features/reviewable/components/EditArtistApplication"
      ),
  ),
);
const EditArtistReview = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "EditArtistReview" */ "./pages/EditArtistReview"
      ),
  ),
);
const EditProfile = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "EditProfile" */ "./features/profile/components/EditProfile"
      ),
  ),
);
const FAQ = React.lazy(() =>
  importRetry(() => import(/* webpackChunkName: "FAQ" */ "./pages/FAQ")),
);
const ForgotPassword = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "ForgotPassword" */ "./pages/ForgotPassword"),
  ),
);
const ResourceLibrary = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ResourceLibrary" */ "./pages/ResourceLibrary"
      ),
  ),
);
const HomePageV2 = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "HomePage" */ "./pages/HomePageV2"),
  ),
);
const InnovationLab = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "InnovationLab" */ "./pages/InnovationLab"),
  ),
);
const InnovationLabV2 = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "InnovationLabV2" */ "./pages/InnovationLabV2"
      ),
  ),
);
const InnovationLabDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "InnovationLabDetails" */ "./pages/InnovationLabDetails"
      ),
  ),
);
const InnovationLabIIDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "InnovationLabIIDetails" */ "./pages/InnovationLabIIDetails"
      ),
  ),
);
const WebinarFFCTaxStrategy = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "WebinarFFCTaxStrategy" */ "./pages/WebinarFFCTaxStrategy"
      ),
  ),
);
const HUGFest = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "HUGFest" */ "./pages/HUGFest"),
  ),
);
const webinarFFCArtLicensing = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "webinarFFCArtLicensing" */ "./pages/WebinarFFCArtLicensing"
      ),
  ),
);
const WebinarRefikAnadol = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "WebinarRefikAnadol" */ "./pages/WebinarRefikAnadol"
      ),
  ),
);
const HUGxLENSBootcamp = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "HUGxLENSBootcamp" */ "./pages/HUGxLENSBootcamp"
      ),
  ),
);
const Instagram = React.lazy(() =>
  importRetry(() => import("./pages/Instagram")),
);
const InviteCreator = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "InviteCreator" */ "./features/reviewable/components/InviteCreator"
      ),
  ),
);
const MobileSignIn = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "MobileSignIn" */ "./features/profile/components/MobileSignIn"
      ),
  ),
);
const NotFound = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "NotFound" */ "./pages/NotFound"),
  ),
);
const Onboarding = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "Onboarding" */ "./pages/Onboarding"),
  ),
);
const OpenCallAdminCreate = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallAdminCreate" */ "./pages/OpenCallAdminCreate"
      ),
  ),
);
const OpenCallAdminDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallAdminDetails" */ "./pages/OpenCallAdminDetails"
      ),
  ),
);
const OpenCallAdminEdit = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallAdminEdit" */ "./pages/OpenCallAdminEdit"
      ),
  ),
);
const OpenCallAdminExport = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallAdminExport" */ "./pages/OpenCallAdminExport"
      ),
  ),
);
const OpenCallAdminList = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallAdminList" */ "./pages/OpenCallAdminList"
      ),
  ),
);
const OpenCallDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallDetails" */ "./pages/OpenCallDetails"
      ),
  ),
);
const OpenCallList = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "OpenCallList" */ "./pages/OpenCallList"),
  ),
);
const OpenCallPartnerCuration = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallPartnerCuration" */ "./pages/OpenCallPartnerCuration"
      ),
  ),
);
const OpenCallSubmissionCreate = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallSubmissionCreate" */ "./pages/OpenCallSubmissionCreate"
      ),
  ),
);
const OpenCallSubmissionDetails = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallSubmissionDetails" */ "./pages/OpenCallSubmissionDetails"
      ),
  ),
);
const OpenCallSubmissionEdit = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "OpenCallSubmissionEdit" */ "./pages/OpenCallSubmissionEdit"
      ),
  ),
);
const OpenCallVoting = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "OpenCallVoting" */ "./pages/OpenCallVoting"),
  ),
);
const PrivacyPolicy = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "PrivacyPolicy" */ "./pages/PrivacyPolicy"),
  ),
);
const Profile = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "Profile" */ "./pages/Profile"),
  ),
);
const RaffleReport = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "RaffleReport" */ "./features/admin/components/RaffleReport"
      ),
  ),
);
const ReviewModeration = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ReviewModeration" */ "./pages/ReviewModeration"
      ),
  ),
);
const RewardsShop = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "RewardsShop" */ "./pages/RewardsShop"),
  ),
);
const SellerTerms = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "SellerTerms" */ "./pages/SellerTerms"),
  ),
);
const Search = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "Search" */ "./features/search/components/Search"
      ),
  ),
);
const Shop = React.lazy(() =>
  importRetry(() => import(/* webpackChunkname: "Shop" */ "./pages/Shop")),
);
const ShopAllArtists = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkname: "ShopAllArtists" */ "./pages/ShopAllArtists"),
  ),
);
const ShopCollection = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkname: "ShopCollection" */ "./pages/ShopCollection"),
  ),
);
const ShopCart = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkname: "ShopCart" */ "./pages/ShopCart"),
  ),
);
const ClaimCollectible = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ClaimCollectible" */ "./pages/ClaimCollectible"
      ),
  ),
);
const AccessDigitalDownload = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "AccessDigitalDownload" */ "./pages/AccessDigitalDownload"
      ),
  ),
);
const SupportPage = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "SupportPage" */ "./pages/SupportPage"),
  ),
);
const TermsOfService = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "TermsOfService" */ "./pages/TermsOfService"),
  ),
);
const ShopSellerTermsOfService = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "ShopSellerTermsOfService" */ "./pages/ShopSellerTermsOfService"
      ),
  ),
);
const ResetPassword = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "ResetPassword" */ "./pages/ResetPassword"),
  ),
);
const UserProfile = React.lazy(() =>
  importRetry(
    () => import(/* webpackChunkName: "UserProfile" */ "./pages/UserProfile"),
  ),
);
const UserSearch = React.lazy(() =>
  importRetry(
    () =>
      import(
        /* webpackChunkName: "UserSearch" */ "./features/admin/components/UserSearch"
      ),
  ),
);
const ViewAllUpdates = React.lazy(() =>
  importRetry(
    () =>
      import(/* webpackChunkName: "ViewAllUpdates" */ "./pages/ViewAllUpdates"),
  ),
);

/**
 * Global router configuration object
 */
const routes: RouteObject[] = [
  { index: true, Component: HomePageV2 },
  { path: "artist-profile-benefits", Component: AboutArtistProfile },
  {
    Component: LayoutSite,
    children: [
      { path: "*", Component: NotFound },
      { path: "about", Component: About },
      { path: "artist-application", Component: CreateArtistApplication },
      { path: "artist-invites", Component: ArtistInvites },
      { path: "artist-updates", Component: ViewAllUpdates },
      { path: "auth/instagram", Component: Instagram },
      { path: "banner-maker", Component: BannerMaker },
      { path: "commissions", element: <Redirect url="/shop" /> },
      { path: "dei-initiatives", Component: DeiInitiatives },
      { path: "faq", Component: FAQ },
      {
        path: "for-creators",
        element: <Redirect url={constants.studiosUrl} />,
      },
      { path: "lab", Component: InnovationLab },
      { path: "innolab", Component: InnovationLabV2 },
      { path: "lab-details", Component: InnovationLabDetails },
      { path: "lab-ii-details", Component: InnovationLabIIDetails },
      { path: "webinar", Component: WebinarFFCTaxStrategy },
      { path: "hugfestrsvp", Component: HUGFest },
      { path: "licensingmasterclass", Component: webinarFFCArtLicensing },
      { path: "refikanadol", Component: WebinarRefikAnadol },
      { path: "lensbootcamp", Component: HUGxLENSBootcamp },
      { path: "privacy-policy", Component: PrivacyPolicy },
      { path: "seller-terms", Component: SellerTerms },
      { path: "redeem-hug-points", Component: RewardsShop },
      { path: "search", Component: Search },
      {
        path: "shop",
        children: [
          {
            index: true,
            Component: Shop,
          },
          {
            path: "artists",
            Component: ShopAllArtists,
          },
          {
            path: "collection/:handle",
            Component: ShopCollection,
          },
          {
            path: "cart",
            Component: ShopCart,
          },
        ],
      },
      { path: "support", Component: SupportPage },
      { path: "terms-of-service", Component: TermsOfService },
      { path: "seller-terms-of-service", Component: ShopSellerTermsOfService },
      {
        path: "admin",
        Component: requireAuthorization(AdminWrapper, {
          feature: "showAdminFeatures",
        }),
        children: [
          { path: "reports", Component: CuratorReport },
          { path: "raffles", Component: RaffleReport },
          { path: "applications", Component: ArtistApplications },
          {
            path: "applications/:applicationId/edit",
            Component: EditArtistApplication,
          },
          {
            path: "applications/:applicationId",
            Component: ArtistApplication,
          },
          {
            path: "collectives",
            children: [
              {
                index: true,
                Component: CollectiveAdminList,
              },
              {
                path: "new",
                Component: CollectiveAdminCreate,
              },
              {
                path: ":collectiveId",
                children: [
                  {
                    index: true,
                    Component: CollectiveAdminDetails,
                  },
                  {
                    path: "edit",
                    Component: CollectiveAdminEdit,
                  },
                ],
              },
            ],
          },
          {
            path: "open-calls",
            children: [
              {
                index: true,
                Component: OpenCallAdminList,
              },
              {
                path: ":openCallId",
                children: [
                  {
                    index: true,
                    Component: OpenCallAdminDetails,
                  },
                  {
                    path: "edit",
                    Component: OpenCallAdminEdit,
                  },
                  {
                    path: "export",
                    Component: OpenCallAdminExport,
                  },
                ],
              },
              {
                path: "new",
                Component: OpenCallAdminCreate,
              },
            ],
          },
          {
            path: "shop",
            children: [
              {
                path: "artist-access",
                Component: AdminArtistAccess,
              },
              {
                path: "orders",
                Component: AdminShopOrders,
              },
              {
                path: "products",
                Component: AdminShopProducts,
              },
              {
                path: "tax-documents",
                Component: AdminShopTaxDocuments,
              },
            ],
          },
          {
            path: "/admin/create-update",
            Component: AdminCreatePost,
          },
          {
            path: "/admin/create-user",
            Component: AdminCreateUser,
          },
          { path: "users", Component: UserSearch },
          {
            path: "users/:userId",
            Component: AdminUserDetails,
          },
          {
            path: "edit-home",
            Component: AdminHomePage,
          },
          {
            path: "edit-home-v2-publish",
            Component: AdminHomePageV2Publish,
          },
          {
            path: "hug-points",
            Component: AdminHUGPointGrant,
          },
          {
            path: "image-moderation",
            Component: AdminImageModeration,
          },
        ],
      },
      {
        path: "moderation",
        Component: requireAuthorization(ReviewModeration, {
          feature: "canModerate",
        }),
      },
      {
        path: "artist-voting",
        Component: requireAuthorization(ArtistVoting, { roles: ["isHugger"] }),
      },
      {
        path: "artists",
        children: [
          {
            path: "create",
            Component: requireAuthorization(CreateArtist, {
              feature: "showAdminFeatures",
            }),
          },
          {
            path: ":artistId",
            Component: ArtistProfile,
            children: [
              {
                index: true,
                Component: ArtistProfileOverview,
              },
              {
                path: "about",
                Component: ArtistProfileAbout,
              },
              {
                path: "portfolio",
                children: [
                  {
                    index: true,
                    Component: ArtistProfilePortfolio,
                  },
                  {
                    path: "gallery/:galleryId",
                    Component: ArtistProfilePortfolioGallery,
                  },
                  {
                    path: "item/:itemId",
                    Component: ArtistProfilePortfolioItem,
                  },
                ],
              },
              {
                path: "reviews",
                Component: ArtistProfileReviews,
              },
              {
                path: "shop",
                children: [
                  {
                    index: true,
                    Component: ArtistShop,
                  },
                  {
                    path: ":productHandle",
                    Component: ArtistShopProduct,
                  },
                  {
                    path: "edit",
                    Component: requireShopBuilderAccess(ArtistShopEditWrapper),
                    children: [
                      {
                        index: true,
                        Component: ArtistShopEditor,
                      },
                      {
                        path: "orders",
                        Component: ArtistShopOrders,
                      },
                      {
                        path: "inventory",
                        Component: ArtistShopEditInventory,
                      },
                      {
                        path: "builder-disabled",
                        Component: ProductBuilderDisabled,
                      },
                    ],
                  },
                ],
              },
              {
                path: "updates",
                Component: ArtistProfileUpdates,
              },
            ],
          },
          {
            // Legacy pages not using new profile tab layout
            path: ":artistId",
            children: [
              {
                path: "admin",
                Component: requireAuthorization(ArtistAdmin, {
                  feature: "showAdminFeatures",
                }),
              },
              {
                path: "edit",
                Component: requireAuthentication(EditArtist, {
                  createAccount: true,
                }),
              },
              {
                path: "followers",
                Component: ArtistFollowersPage,
              },
              {
                path: "create-update",
                Component: requireAuthorization(ArtistCreatePost, {
                  feature: "canPost",
                }),
              },
              {
                path: "review",
                Component: requireAuthentication(CreateArtistReview, {
                  createAccount: true,
                }),
              },
              {
                path: "reviews/:reviewedByUserId/edit",
                Component: requireAuthentication(EditArtistReview, {
                  createAccount: true,
                }),
              },
              {
                path: "updates/:postId",
                children: [
                  {
                    index: true,
                    Component: ArtistPost,
                  },
                  {
                    path: "edit",
                    Component: ArtistPostEdit,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "profile",
        children: [
          { index: true, Component: requireAuthentication(Profile) },
          { path: "edit", Component: requireAuthentication(EditProfile) },
          {
            path: "mobileSignIn",
            Component: requireAuthentication(MobileSignIn),
          },
          {
            path: "notifications",
            Component: requireAuthentication(AllNotifications),
          },
          {
            path: "your-actions",
            Component: requireAuthentication(CurationCorner),
          },
        ],
      },
      {
        path: "users",
        children: [{ path: ":userId", Component: UserProfile }],
      },
      {
        path: "collectives",
        children: [
          { index: true, Component: CollectiveList },
          { path: ":slug", Component: CollectiveDetails },
          { path: ":slug/updates", Component: CollectiveAllPosts },
          { path: ":slug/edit", Component: CollectiveEdit },
        ],
      },
      {
        path: "badges",
        children: [{ path: ":slug", Component: CollectiveDetails }],
      },
      {
        path: "open-calls",
        children: [
          { index: true, Component: OpenCallList },
          {
            path: ":openCallId",
            children: [
              { index: true, Component: OpenCallDetails },
              {
                path: "curate",
                Component: OpenCallPartnerCuration,
              },
              {
                path: "submit",
                Component: requireAuthentication(OpenCallSubmissionCreate),
              },
              {
                path: "vote",
                Component: requireAuthentication(OpenCallVoting),
              },
              {
                path: "submissions/:submissionId",
                children: [
                  { index: true, Component: OpenCallSubmissionDetails },
                  {
                    path: "edit",
                    Component: requireAuthentication(OpenCallSubmissionEdit),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "resources",
        children: [
          { index: true, Component: ResourceLibrary },
          { path: ":resourceSlug", Component: ResourceLibrary },
        ],
      },
    ],
  },
  {
    Component: LayoutTask,
    children: [
      {
        path: "/artist-invite/:artistId",
        Component: requireAuthorization(InviteCreator, {
          feature: "showAdminFeatures",
        }),
      },
      {
        path: "/artist-claim/:artistId/:inviteCode",
        Component: ClaimArtistProfile,
      },
      {
        path: "/claim-collectible/:artistId/:fulfillmentLineItemId",
        Component: ClaimCollectible,
      },
      {
        path: "/digital-download/:artistId/:productHandle",
        Component: AccessDigitalDownload,
      },
      {
        path: "/checkout",
        children: [
          {
            index: true,
            Component: requireAuthentication(Checkout),
          },
          {
            path: "confirmation",
            Component: requireAuthentication(CheckoutConfirmation),
          },
        ],
      },
    ],
  },
  {
    Component: LayoutTaskBlank,
    children: [
      {
        path: "artists/:artistId/shop/edit/products",
        children: [
          {
            index: true,
            Component: requireShopBuilderAccess(ArtistShopProductCreate),
          },
          {
            path: ":productId",
            Component: requireShopBuilderAccess(ArtistShopProductWizard),
          },
        ],
      },
    ],
  },
  {
    Component: LayoutFull,
    children: [
      // NOTE: this is helpful for testing components or CSS while developing
      ...(isDev ? [{ path: "/dev", Component: Dev }] : []),
      // NOTE: SignIn component is rendered by two different paths for small change in copy
      { path: "/sign-in", element: <SignIn /> },
      { path: "/register", Component: CreateAccountEmail },
      { path: "/create-account", element: <SignIn isCreateAccount /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      {
        path: "/reset-password",
        Component: requireAuthentication(ResetPassword),
      },
      // NOTE: This route should only be routed to programmatically after SignIn process
      { path: "/onboarding", Component: requireAuthentication(Onboarding) },
    ],
  },
  {
    path: "artists/:artistId",
    /** NOTE: PortfolioEditor handles authorization checks for all child routes */
    Component: requireAuthentication(ArtistProfilePortfolioLayout),
    children: [
      {
        path: "profile/edit",
        Component: requireAuthentication(ArtistProfileEditDetails),
      },
      {
        path: "featured/edit",
        Component: ArtistProfileFeaturedEdit,
      },
      {
        path: "portfolio",
        children: [
          {
            path: "edit",
            Component: ArtistProfilePortfolioEdit,
          },
          {
            path: "reorder",
            Component: ArtistProfilePortfolioReorder,
          },
          {
            path: "gallery/:galleryId/reorder",
            Component: ArtistProfilePortfolioReorder,
          },
          {
            path: "gallery/:galleryId/edit",
            Component: ArtistProfileGalleryEdit,
          },
          {
            path: "item/:itemId/edit",
            Component: ArtistProfileGalleryItemEdit,
          },
        ],
      },
    ],
  },
];

function Redirect({ url }: { url: string }) {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
}

const resetPasswordRoute = "/reset-password";
const onboardingRoute = "/onboarding";

function Router() {
  const queryClient = useQueryClient();
  const {
    signOut,
    user,
    roles: { isCollector, isCurator },
  } = useAuth();
  const { disconnect } = useWalletAuth();
  const location = useLocation();
  const navigate = useNavigate();

  usePageTracking();

  // Check user object for any forced tasks
  React.useEffect(() => {
    let forceRoute = "";

    if (
      user?.forcePassword &&
      !location.pathname.startsWith(resetPasswordRoute)
    ) {
      forceRoute = resetPasswordRoute;
    } else if (
      user?.forceOnboarding &&
      !isCollector &&
      !isCurator &&
      !location.pathname.startsWith(onboardingRoute)
    ) {
      forceRoute = onboardingRoute;
    }

    if (forceRoute) {
      navigate(forceRoute, { state: { from: location } });
    }
  }, [user, location, navigate, isCollector, isCurator]);

  React.useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        // If any query resolves in an error that indicates the token is invalid, use methods from
        // Auth provider to sign them out which would allow a clean slate to sign back in and get
        // a new valid token
        onError: async (error) => {
          if (
            error instanceof Error &&
            error.message.includes("Authorization token is invalid")
          ) {
            disconnect();
            signOut();
          }
        },
      },
    });
  }, [queryClient, signOut, disconnect]);

  // Scroll on all transitions
  React.useLayoutEffect(() => {
    if (location.hash && location.hash !== "#" && location.hash !== "") {
      // Try to find hash and scroll to it, waiting for loads if necessary
      asyncScrollToHash();
    } else {
      // Path changed and/or hash was removed, scroll to top
      scrollToTop();
    }
  }, [location.hash, location.pathname]);

  return (
    <>
      <Helmet>
        <title>{defaultMetaData.pageTitle}</title>
        <link
          rel="canonical"
          href={`https://thehug.xyz${location.pathname}`}
          data-router
        />
      </Helmet>
      {useRoutes(routes)}
    </>
  );
}

export { Router };
