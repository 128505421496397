import * as React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import {
  AllCaps,
  Avatar,
  Button,
  Container,
  DropdownMenu,
  FlexRow,
  H2,
  Hr,
  IconClose,
  IconChevron,
  IconDiamond,
  IconHugLogo,
  IconMenu,
  List,
  Link,
  Text,
  Stack,
  IconSearch,
} from "atoms";
import { GlobalSearch } from "features/search";
import { User, useAuth, useWalletAuth } from "features/auth";
import { AnnouncementList, useNotifications } from "features/notifications";
import {
  HUGS,
  useProfilePoints,
  useCurationOpportunities,
} from "features/profile";
import { useArtist } from "features/reviewable";
import { CartButton, productCategories } from "features/shop";
import { constants } from "config";
import { trackEvent } from "contexts/mixpanel";
import { preventForwarding } from "styles/styleUtils";
import { paths } from "utils/paths";
import { HeaderMenuLink } from "./HeaderMenuLink";

const trackSignIn = () =>
  trackEvent({
    name: "Button Click",
    ButtonName: "Sign In",
    ButtonContext: "Site Header",
  });
const trackOpenSearch = () => {
  trackEvent({
    name: "Button Click",
    ButtonName: "Search Open",
    ButtonContext: "Site Header",
  });
};

const DESKTOP_WIDTH = 830;

const unscrollableStyles = `
  #root {
    @media(max-width: ${DESKTOP_WIDTH}px) {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
  }
`;

type ActiveDropdown =
  | null
  | "account"
  | "artists"
  | "cart"
  | "nav"
  | "resources"
  | "search"
  | "shop";

type HeaderProps = {
  minimal?: boolean;
  isSticky?: boolean;
};

function SiteHeader({
  minimal = false,
  isSticky = true,
  ...props
}: HeaderProps) {
  const {
    user,
    roles: { isCurator, isQA, showAdminFeatures },
    signOut,
  } = useAuth();
  const showLabLink =
    showAdminFeatures || user?.roles.includes("innovation-lab-2024");
  const showLabIILink =
    showAdminFeatures || user?.roles.includes("innovation-lab-v2-2024");

  const location = useLocation();

  // Site header visibility
  const [isHeaderShowing, setIsHeaderShowing] = React.useState(true);
  const lastKnownScrollPosition = React.useRef<number>(window.scrollY);
  const scrolled = React.useRef<boolean>(false);

  // handles site header dropdown states
  const [activeDropdown, setActiveDropdown] =
    React.useState<ActiveDropdown>(null);
  const createActiveHandler = (dropdown: ActiveDropdown) => () => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);

      if (dropdown === "search") {
        trackOpenSearch();
      }
    }
  };

  // On navigation change, make sure dropdowns are hidden
  React.useEffect(() => {
    setActiveDropdown(null);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location]);

  /**
   * When focus leaves the parent element of our dropdown menus, reset to close them.
   * NOTE: we're running this event on the capture phase to prevent child elements firing this same event handler.
   */
  const onBlurCapture: React.FocusEventHandler = (event) => {
    const { relatedTarget, currentTarget } = event;
    if (relatedTarget !== null) {
      // Event will travel from root element to this element, but we want to stop it from continuing to this element's children
      event.stopPropagation();

      // Current target is the element we attached the handler to, and the related target is the newly focused element. If the new
      // element is not a descendent of the current target, then we are outside of the nav element.
      if (!currentTarget.contains(relatedTarget)) {
        setActiveDropdown(null);
      }
    }
  };

  // Hides all dropdowns when one is open and screen is resized to deskop breakpoint
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > DESKTOP_WIDTH) {
        setActiveDropdown(null);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useLayoutEffect(() => {
    const checkPosition = () => {
      // shows header at the top or bottom of page
      const atTop = window.scrollY < 200;
      const atBottom =
        Math.round(document.documentElement.getBoundingClientRect().height) <
        window.scrollY + window.innerHeight + 300;
      const scrollsUp = lastKnownScrollPosition.current - window.scrollY >= 0;

      setIsHeaderShowing(atTop || atBottom || scrollsUp);

      lastKnownScrollPosition.current = window.scrollY;
      scrolled.current = false;
    };

    const scrollHandler = () => {
      if (!scrolled.current) {
        scrolled.current = true;
        window.requestAnimationFrame(checkPosition);
      }
    };

    const timeout = window.setTimeout(() => {
      window.addEventListener("scroll", scrollHandler);
    }, 200);

    return () => {
      window.clearTimeout(timeout);
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const isHomePage = location.pathname === "/";
  const showAnnouncements = !minimal && isHomePage && !!user;

  return (
    <Header isHeaderShowing={isHeaderShowing} isSticky={isSticky} {...props}>
      {!!activeDropdown && (
        <Helmet>
          <style>{unscrollableStyles}</style>
        </Helmet>
      )}

      {showAnnouncements && <AnnouncementList />}

      <SContainer width="lg">
        <Logo to="/" aria-label="Home">
          <IconHugLogo aria-label="Hug Logo" />
        </Logo>

        {
          !minimal && (
            <>
              <SiteNavWrapper onBlurCapture={onBlurCapture}>
                <SiteNav
                  aria-label="Main navigation"
                  className="mainNavigation"
                >
                  <SiteNavDropdown>
                    <NavDropdownToggle
                      aria-expanded={activeDropdown === "nav"}
                      type="button"
                      onClick={createActiveHandler("nav")}
                      aria-label={`${activeDropdown ? "Close" : "Open"} Menu`}
                      variant="secondary"
                    >
                      {activeDropdown === "nav" ? (
                        <IconClose aria-hidden="true" />
                      ) : (
                        <IconMenu aria-hidden="true" />
                      )}
                    </NavDropdownToggle>

                    <NavDropdownMenu
                      isOpen={activeDropdown === "nav"}
                      hasArrow
                      isFixed
                    >
                      <SubMenuDropdown isOpen={activeDropdown === "artists"}>
                        <SubMenuDropdownToggle
                          onClick={createActiveHandler("artists")}
                        >
                          Discover Artists{" "}
                          <IconChevron className="DropdownArrow" />
                        </SubMenuDropdownToggle>
                        <SubMenuDropdownMenu
                          isOpen={activeDropdown === "artists"}
                        >
                          <SubMenuHeading>Discover Artists</SubMenuHeading>
                          {!user?.claimedArtistPageIds?.length && (
                            <HeaderMenuLink
                              to={paths.artistProfileBenefits()}
                              label="Apply for Artist Profile"
                            />
                          )}
                          <HeaderMenuLink
                            to="/search"
                            label="Artist Profiles"
                          />
                          <HeaderMenuLink
                            to="/collectives"
                            label="Artist Collectives"
                          />
                          <HeaderMenuLink
                            to="/artist-updates"
                            label="Artist Updates"
                          />
                        </SubMenuDropdownMenu>
                      </SubMenuDropdown>
                      <TopLevelHeaderMenuLink
                        to="/open-calls"
                        label="Open Calls"
                      />

                      {/* <TopLevelHeaderMenuLink to="/shop" label="Shop" /> */}

                      <SubMenuDropdown isOpen={activeDropdown === "shop"}>
                        <SubMenuDropdownToggle
                          onClick={createActiveHandler("shop")}
                        >
                          Shop <IconChevron className="DropdownArrow" />
                        </SubMenuDropdownToggle>

                        <SubMenuDropdownMenu isOpen={activeDropdown === "shop"}>
                          <SubMenuHeading>Shop</SubMenuHeading>

                          <HeaderMenuLink to="/shop" label="Shop All Products">
                            <span>Shop All Products</span>
                          </HeaderMenuLink>
                          {productCategories.map((cat) => (
                            <HeaderMenuLink
                              to={cat.url}
                              label={cat.label}
                              key={cat.label}
                            >
                              <span>{cat.label}</span>
                            </HeaderMenuLink>
                          ))}
                        </SubMenuDropdownMenu>
                      </SubMenuDropdown>

                      <SubMenuDropdown isOpen={activeDropdown === "resources"}>
                        <SubMenuDropdownToggle
                          onClick={createActiveHandler("resources")}
                        >
                          Resources <IconChevron className="DropdownArrow" />
                        </SubMenuDropdownToggle>
                        <SubMenuDropdownMenu
                          isOpen={activeDropdown === "resources"}
                        >
                          <SubMenuHeading>Resources</SubMenuHeading>
                          <HeaderMenuLink
                            href={`${constants.studiosUrl}/lab`}
                            label="Innovation Laboratory"
                            target="_blank"
                            showExternalLinkIcon
                          >
                            <span>Innovation Laboratory</span>
                          </HeaderMenuLink>
                          <HeaderMenuLink
                            href={constants.studiosUrl}
                            label="HUG Studios"
                            target="_blank"
                            showExternalLinkIcon
                          >
                            <span>
                              <AllCaps>Hug</AllCaps> Studios
                            </span>
                          </HeaderMenuLink>

                          <HeaderMenuLink
                            href={constants.galleryPartnersUrl}
                            label="Gallery Partners"
                            target="_blank"
                            showExternalLinkIcon
                          >
                            Gallery Partners
                          </HeaderMenuLink>

                          <HeaderMenuLink
                            to="/resources"
                            label="Free Guides & Tools"
                          >
                            Free Guides &amp; Tools
                          </HeaderMenuLink>
                          <HeaderMenuLink
                            href={constants.creatorRoyaltiesURL}
                            target="_blank"
                            label="Weekly Creator Newsletter"
                          />
                          <HeaderMenuLink
                            to="/banner-maker"
                            label="Banner Maker"
                          />
                        </SubMenuDropdownMenu>
                      </SubMenuDropdown>
                    </NavDropdownMenu>
                  </SiteNavDropdown>
                </SiteNav>
              </SiteNavWrapper>

              <SiteHeaderSearch onBlurCapture={onBlurCapture}>
                <SearchDropdown
                  isActive={activeDropdown === "search"}
                  toggleDropdownShowing={createActiveHandler("search")}
                />
              </SiteHeaderSearch>

              <SiteHeaderCart>
                <CartButton />
              </SiteHeaderCart>

              <SiteAccount onBlurCapture={onBlurCapture}>
                <FlexRow gap="10px" flexWrap="nowrap">
                  {user ? (
                    <AccountDropdown
                      user={user}
                      isActive={activeDropdown === "account"}
                      toggleDropdownShowing={createActiveHandler("account")}
                      isCurator={isCurator}
                      isQA={isQA}
                      showAdminFeatures={showAdminFeatures}
                      showLabLink={showLabLink ?? false}
                      showLabIILink={showLabIILink ?? false}
                      signOut={signOut}
                    />
                  ) : (
                    <SignInButton
                      to="/sign-in"
                      size="xs"
                      variant="primary"
                      onClick={trackSignIn}
                    >
                      Sign In
                    </SignInButton>
                  )}
                </FlexRow>
              </SiteAccount>
            </>
          )
          // Ends minimal style
        }
      </SContainer>
    </Header>
  );
}

type SiteHeaderDropdownProps = {
  isActive: boolean;
  toggleDropdownShowing: VoidFunction;
};

type SiteHeaderAccountDropdownProps = {
  user: User;
  isActive: boolean;
  isCurator: boolean;
  isQA: boolean;
  showAdminFeatures: boolean;
  showLabLink: boolean;
  showLabIILink: boolean;
  signOut: VoidFunction;
  toggleDropdownShowing: VoidFunction;
};

const SiteHeaderSearch = styled.div({
  gridArea: "search",
  position: "relative",
  alignSelf: "stretch",
  display: "flex",
  alignItems: "center",
});
const SiteHeaderCart = styled.div({
  gridArea: "cart",
  position: "relative",
  alignSelf: "stretch",
  display: "flex",
  alignItems: "center",
});

function SearchDropdown({
  isActive,
  toggleDropdownShowing,
}: SiteHeaderDropdownProps) {
  return (
    <Dropdown isOpen={isActive}>
      <SearchDropdownToggle
        onClick={toggleDropdownShowing}
        arrow={null}
        aria-expanded={isActive}
        aria-label="Artist Search"
      >
        <IconSearch />
      </SearchDropdownToggle>
      {isActive && (
        <SiteHeaderDropdownMenu isOpen hasArrow isFixed>
          <GlobalSearch />
        </SiteHeaderDropdownMenu>
      )}
    </Dropdown>
  );
}

function AccountDropdown({
  user,
  isActive,
  toggleDropdownShowing,
  signOut,
  ...props
}: SiteHeaderAccountDropdownProps) {
  const { disconnect } = useWalletAuth();

  // Get notification count
  const { data: notifications } = useNotifications();
  const unreadNotifications = notifications
    ? notifications.filter((d) => !d.readAt)
    : [];
  const notificationCount = unreadNotifications.length;

  // Determine user's avatar src or fallback to HUG token
  let avatarSrc;
  if (user?.avatar) {
    avatarSrc = user.avatar.url150 ?? user.avatar.url300 ?? user.avatar.url;
  } else {
    avatarSrc = `https://thehug.xyz/assets/tokens/${
      HUGS[user?.hugImageIds?.[0] ?? 0].image
    }`;
  }

  // Handle signout and wallet disconnect
  const handleSignOut = () => {
    disconnect();
    signOut();
  };

  return (
    <Dropdown isOpen={isActive}>
      <AvatarDropdownToggle
        onClick={toggleDropdownShowing}
        isOpen={isActive}
        arrow={null}
        aria-expanded={isActive}
        aria-label="My account"
      >
        <Avatar
          alt="avatar"
          src={avatarSrc}
          aria-hidden="true"
          size={buttonSize}
        />
        {notificationCount > 0 && (
          <CountCircle isNotification>{notificationCount}</CountCircle>
        )}
      </AvatarDropdownToggle>

      {isActive && (
        <AccountDropdownInner
          handleSignOut={handleSignOut}
          notificationCount={notificationCount}
          user={user}
          {...props}
        />
      )}
    </Dropdown>
  );
}

type AccountDropdownInnerProps = {
  handleSignOut: VoidFunction;
  notificationCount: number;
} & Omit<
  SiteHeaderAccountDropdownProps,
  "isActive" | "signOut" | "toggleDropdownShowing"
>;

function AccountDropdownInner({
  handleSignOut,
  isCurator,
  isQA,
  showAdminFeatures,
  showLabLink,
  showLabIILink,
  notificationCount,
  user,
}: AccountDropdownInnerProps) {
  // Get curation count
  const { data } = useCurationOpportunities();
  const curationCount = data?.total ?? 0;
  const curationLink = (
    <HeaderMenuLink to="/profile/your-actions" label="Voting & Curation">
      Voting & Curation
      {curationCount > 0 && <CurationCount>{curationCount}</CurationCount>}
    </HeaderMenuLink>
  );
  const { data: profilePoints } = useProfilePoints(user?.id ?? "");

  return (
    <SiteHeaderDropdownMenu isOpen hasArrow isFixed>
      <Stack gap="xs">
        <MyAccountSubMenuLink to="/profile" label="My Account">
          My Account
          {profilePoints && (
            <SScore>
              <IconDiamond accent="accent1" aria-label="Hug points:" />{" "}
              <span>{profilePoints?.pointsBalance.toLocaleString()}</span>
            </SScore>
          )}
        </MyAccountSubMenuLink>

        <ArtistProfileLinks artistProfiles={user?.claimedArtistPageIds ?? []} />

        <HeaderMenuLink to="/profile/notifications/" label="Notifications">
          Notifications
          {notificationCount > 0 && (
            <CountCircle>{notificationCount}</CountCircle>
          )}
        </HeaderMenuLink>
      </Stack>

      <SHr />

      <Stack gap="xs">
        <HeaderMenuLink to="/artist-invites" label="Invite Artists" />
        {isCurator && curationLink}
        <HeaderMenuLink to="/redeem-hug-points" label="Redeem Hug Points" />
      </Stack>

      {(showAdminFeatures || isQA) && <SHr />}

      <Stack gap="xs">
        {showLabLink && (
          <HeaderMenuLink to="/lab-details" label="Innovation Lab Details" />
        )}
        {showLabIILink && (
          <HeaderMenuLink
            to="/lab-ii-details"
            label="Innovation Lab II Details"
          />
        )}
        {showAdminFeatures && (
          <HeaderMenuLink to="/admin/users" label="Admin" />
        )}
        <HeaderMenuLink to="/" onClick={handleSignOut} label="Sign Out" />
      </Stack>
    </SiteHeaderDropdownMenu>
  );
}

function ArtistName({ artistId }: { artistId: string }) {
  const { data: artist } = useArtist(artistId);
  const name = artist?.name;
  return <>{name}</>;
}

function ArtistProfileLinks({ artistProfiles }: { artistProfiles: string[] }) {
  const artistProfileCount = artistProfiles.length ?? 0;

  if (artistProfileCount === 0) {
    return null;
  }

  if (artistProfileCount === 1) {
    return (
      <>
        <HeaderMenuLink
          to={`/artists/${artistProfiles[0]}`}
          label="My Artist Profile"
        />
        <HeaderMenuLink
          to={paths.artistShopEdit(artistProfiles[0])}
          label="My Shop Manager"
        />
      </>
    );
  }

  return (
    <>
      <H2 size="xxs" style={{ opacity: 0.85, marginTop: "1rem" }}>
        My Artist Profiles
      </H2>
      <List type="blank">
        <Stack gap="xxs">
          {artistProfiles.map((artistId) => (
            <Text size="xxs" key={artistId}>
              <SLink to={`/artists/${artistId}`}>
                <ArtistName artistId={artistId} />
              </SLink>
            </Text>
          ))}
        </Stack>
      </List>
    </>
  );
}

const buttonSize = 36;

const SContainer = styled(Container)(({ theme }) => ({
  alignItems: "center",
  display: "grid",
  height: 56,
  gridTemplateAreas: `
    "logo search cart account nav"
  `,
  gridTemplateColumns: "1fr auto auto auto auto",
  gap: `0 10px`,
  position: "relative",
  zIndex: 3,
  [theme.breakpoints.tablet]: {
    gap: `0 6px`,
    gridTemplateAreas: `
      "logo nav search cart account"
    `,
    gridTemplateColumns: "auto 1fr auto auto auto",
  },
  "@media (min-width: 500px) and (max-width: 830px)": {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  [theme.breakpoints.desktop]: {
    gridTemplateAreas: `"logo nav search cart account"`,
    gridTemplateColumns: "auto 1fr auto auto auto",
  },
}));

const Header = styled.header<{ isHeaderShowing: boolean; isSticky: boolean }>(
  ({ isHeaderShowing, isSticky, theme }) => ({
    background: theme.colors.bg,
    position: "relative",
    flex: "0 0 auto",
    ...(isSticky && {
      position: "sticky",
      top: 0,
      opacity: 1,
      transform: "translateY(0)",
      ...(!isHeaderShowing && {
        opacity: 0,
        transform: "translateY(-100%)",
      }),
      "@media (prefers-reduced-motion: no-preference)": {
        transition: "all 0.5s ease",
      },
    }),
    width: "100%",
    zIndex: 500,
    paddingTop: "env(safe-area-inset-top)",
    "&::before": {
      boxShadow: theme.boxShadow.light,
      bottom: 0,
      content: "''",
      height: 20,
      left: 0,
      position: "absolute",
      right: 0,
      "@media (prefers-reduced-motion: no-preference)": {
        transition: "all 0.5s ease",
      },
      zIndex: 1,
      ...(isSticky &&
        !isHeaderShowing && {
          boxShadow: "none",
        }),
    },
    "&::after": {
      content: "''",
      position: "absolute",
      backgroundColor: theme.colors.bg,
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      zIndex: 2,
    },
  }),
);

const Logo = styled(Link)({
  gridArea: "logo",
  marginBlock: 10,
  marginInline: 0,
  "&, svg": {
    width: 50,
    height: 28,
  },

  "@media (min-width: 720px)": {
    height: 33.7,
    "&, svg": {
      width: 60,
      height: 33.7,
    },
  },
  "@media (prefers-reduced-motion: no-preference)": {
    transition: "transform 0.15s ease",
  },
});

const SiteNavWrapper = styled.div({
  gridArea: "nav",
  paddingTop: 9,
  paddingBottom: 9,
  position: "relative",
});

const SiteAccount = styled.div({
  gridArea: "account",
  paddingTop: 9,
  paddingBottom: 9,
  position: "relative",
});

const SiteNav = styled.nav(({ theme }) => ({
  background: theme.colors.bg,
  display: "flex",
  [theme.breakpoints.tablet]: {
    alignItems: "center",
    background: "none",
    display: "flex",
    flex: "0 0 auto",
    flexDirection: "row",
    justifyContent: "center",
    gap: "1em",
    height: "auto",
    overflow: "visible",
    paddingRight: 0,
    position: "static",
  },
}));

const SignInButton = styled(Link)(({ theme }) => ({
  justifyContent: "center",
  order: 99,
  whiteSpace: "nowrap",
  [theme.breakpoints.tablet]: {
    marginTop: "0",
  },
}));

type WithIsOpen = { isOpen: boolean };

const Dropdown = styled.div<WithIsOpen>(({ isOpen }) => ({
  alignItems: "center",
  alignSelf: "stretch",
  display: "inline-flex",
  textAlign: "left",
  ".DropdownArrow": {
    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
  },
}));

const DropdownToggle = styled(Button)(({ theme }) => ({
  ...theme.fonts.display,
  appearance: "none",
  backgroundColor: theme.colors.black,
  border: 0,
  display: "flex",
  fontWeight: "400",
  fontSize: theme.fontSizes.lg,
  minWidth: buttonSize,
  padding: 0,

  ".DropdownArrow": {
    display: "none",
    fontSize: ".6em",
    pointerEvents: "none",
    [theme.breakpoints.tablet]: {
      display: "inline-block",
      marginRight: -2,
    },
  },
  img: {
    height: buttonSize,
    width: buttonSize,
  },
  "&:hover, &:focus-visible": {
    color: theme.colors.fg,
    transform: "none",
  },
  "&:disabled": {
    background: "unset",
    color: theme.colors.fg,
  },
  [theme.breakpoints.tablet]: {
    alignItems: "center",
    padding: 0,
    zIndex: 1,
  },
}));
const SiteHeaderDropdownMenu = styled(DropdownMenu)<{ isFixed?: boolean }>(
  ({ isFixed = false, theme }) => ({
    borderRadius: 0,
    top: "100%",
    // semi-hacky fix to prevent header menus from hiding under the bottom of the viewport
    ".isEditorLayout &": {
      [theme.breakpoints.maxTablet]: {
        top: 58,
      },
    },
    width: "100%",
    ".mainNavigation &>*": {
      marginBottom: 0,
      marginTop: 0,
    },
    ...(isFixed && {
      position: "fixed",
      "&+.DropdownMenuArrow": { transform: "translate(-50%, -90%)" },
    }),
    [theme.breakpoints.tablet]: {
      width: "max-content",
      minWidth: 250,
      maxWidth: 450,
      borderRadius: theme.borderRadius.md,
      padding: `${theme.spacing.md} calc(${theme.spacing.sm} + 6px)`,
      right: "-.5rem",
      ...(isFixed && {
        position: "absolute",
      }),
      "@media (prefers-reduced-motion: no-preference)": {
        transition: "opacity ease-in 0.2s",
      },
    },
  }),
);

const SiteNavDropdown = styled.div(({ theme }) => ({
  display: "block",
  [theme.breakpoints.tablet]: {
    flexDirection: "row",
    display: "block",
    position: "relative",
    boxShadow: "none",
    padding: 0,
    margin: 0,
    overflow: "initial",
    zIndex: 101,
  },
}));

const NavDropdownToggle = styled(DropdownToggle)(({ theme }) => ({
  appearance: "none",
  backgroundColor: theme.colors.transparent,
  display: "inline-flex",
  justifyContent: "center",
  padding: 0,
  gap: 0,
  height: buttonSize,
  width: buttonSize,
  "&:hover, &:focus-visible": {
    backgroundColor: theme.colors.transparent,
  },
  svg: {
    width: buttonSize,
    maxHeight: 28,
    maxWidth: 28,
    margin: 0,
  },
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));

const NavDropdownMenu = styled(SiteHeaderDropdownMenu)(({ theme }) => ({
  padding: "1.5rem 1.5rem 3rem",
  [theme.breakpoints.maxTablet]: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 100px)",
  },
  [theme.breakpoints.tablet]: {
    alignItems: "center",
    boxShadow: "none",
    borderRadius: 0,
    display: "flex",
    justifyContent: "flex-start",
    overflow: "initial",
    margin: 0,
    position: "static",
    padding: 0,
    opacity: "1",
    gap: "1rem",
    "&>div": {
      flexDirection: "row",
      whiteSpace: "nowrap",
      gap: ".7em",
    },
    "&::before, &::after": {
      content: "none",
    },
  },
  "@media (min-width: 640px)": {
    "&>div": {
      gap: "1em",
    },
  },
  [theme.breakpoints.desktop]: {
    alignItems: "center",
    justifyContent: "flex-start",
    "&>div": {
      gap: "1.25em",
    },
  },
}));

const MyAccountSubMenuLink = styled(HeaderMenuLink)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: "100%",
});

const Score = styled(Text)(({ theme }) => ({
  ...theme.fonts.body,
  display: "inline-flex",
  alignItems: "center",
  gap: 5,
  fontWeight: "bold",
  width: "min-content",
  color: theme.colors.fg,
  lineHeight: "1",
  margin: "0 0 0 8px",
  fontSize: 14,
  textTransform: "none",
  whiteSpace: "nowrap",
  span: {
    position: "relative",
    top: 1,
  },
  svg: {
    display: "inline-block",
    fontSize: "1rem",
  },
}));

const SScore = styled(Score)({
  flex: "0 0 auto",
  display: "inline-flex",
  alignItems: "center",
  margin: "0 0 0 auto",
  width: "auto",
  justifySelf: "flex-end",
  fontSize: 12,
});

const SHr = styled(Hr)(({ theme }) => ({
  height: 1,
  margin: "1.25em 0",
  opacity: ".25",
  [theme.breakpoints.tablet]: {
    margin: ".8em 0",
  },
}));

const CountCircle = styled.span<{ isNotification?: boolean }>(
  ({ isNotification, theme }) => ({
    ...theme.fonts.body,
    alignItems: "center",
    backgroundColor: theme.colors.accent3L,
    borderRadius: theme.borderRadius.round,
    display: "flex",
    letterSpacing: "-.02em",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    height: 20,
    justifyContent: "center",
    minWidth: 20,
    padding: "1px 2px 0px 3px",
    maxWidth: "max-content",
    color: "black",
    ...(isNotification && {
      position: "absolute",
      top: 0,
      right: 0,
      transform: "translate(33%, -40%)",
    }),
  }),
);

const CurationCount = styled(CountCircle)(({ theme }) => ({
  backgroundColor: theme.colors.fg20,
}));

const SubMenuDropdown = styled(Dropdown)(({ theme }) => ({
  [theme.breakpoints.maxTablet]: {
    display: "block",
    "*+&": {
      margin: "2rem 0 0 0",
    },
  },
  position: "relative",
}));

const TopLevelHeaderMenuLink = styled(HeaderMenuLink)(({ theme }) => ({
  marginTop: "1.5rem",
  position: "relative",
  [theme.breakpoints.tablet]: {
    display: "block",
    margin: 0,
    "body &": {
      color: theme.colors.black,
    },
    fontSize: 15,
    pointerEvents: "auto",
  },
  "@media (min-width:680px)": {
    fontSize: theme.fontSizes.sm,
  },
  [theme.breakpoints.desktop]: {
    fontSize: theme.fontSizes.sm,
  },
}));

const SubMenuDropdownToggle = styled(DropdownToggle)(({ theme }) => ({
  display: "none",
  padding: 0,
  background: theme.colors.transparent,
  borderRadius: 0,
  gap: 2,
  whiteSpace: "nowrap",
  fontSize: theme.fontSizes.md,
  pointerEvents: "none",
  border: 0,
  borderWidth: "2px 0 2px 0",
  borderStyle: "solid",
  borderColor: theme.colors.transparent,
  cursor: "pointer",
  textTransform: "uppercase",
  transition: ".2s ease border-color",
  "&:hover, &:focus-visible": {
    borderColor: theme.colors.transparent,
    borderBottomColor: theme.colors.accent1,
    backgroundColor: theme.colors.transparent,
  },
  [theme.breakpoints.tablet]: {
    display: "block",
    "body &": {
      color: theme.colors.black,
    },
    fontSize: 15,
    pointerEvents: "auto",
  },
  "@media (min-width:680px)": {
    fontSize: theme.fontSizes.sm,
  },
  [theme.breakpoints.desktop]: {
    fontSize: theme.fontSizes.sm,
  },
}));

const SubMenuHeading = styled.p(({ theme }) => ({
  ...theme.fonts.display,
  color: theme.colors.gray60,
  fontSize: theme.fontSizes.xs,
  marginBottom: 5,
  textTransform: "uppercase",
  [theme.breakpoints.tablet]: {
    display: "none",
  },
}));

const SubMenuDropdownMenu = styled(SiteHeaderDropdownMenu)(({ theme }) => ({
  position: "absolute",
  [theme.breakpoints.maxTablet]: {
    borderRadius: 0,
    boxShadow: "none",
    display: "block",
    position: "static",
    padding: 0,
  },
  [theme.breakpoints.tablet]: {
    borderTop: 0,
    right: "auto",
    left: "50%",
    transform: "translate(-50%, 12px)",
    "&::before, &::after": {
      marginLeft: -10,
      left: "auto",
      right: "calc(100% - 23px)",
    },
    "&::after": {
      borderWidth: 9,
      marginLeft: -9,
    },
  },
}));

const AvatarDropdownToggle = styled(DropdownToggle, {
  shouldForwardProp: preventForwarding<WithIsOpen>("isOpen"),
})<WithIsOpen>(({ isOpen, theme }) => ({
  position: "relative",
  backgroundColor: theme.colors.white,
  boxShadow: `inset 0 0 0 1px ${theme.colors.fg30}`,
  img: {
    filter: isOpen ? "none" : "grayscale(1) contrast(1.5)",
    pointerEvents: "none",
    mixBlendMode: "multiply",
  },
  "&:hover img, &:focus-visible img": {
    filter: "none",
  },
  zIndex: 999,
  "&::before, &::after": {
    display: "none",
  },
  "&, &:hover,&:focus, &:focus-visible, &:active": {
    color: theme.colors.bg,
  },
}));

const SearchDropdownToggle = styled(DropdownToggle)(({ theme }) => ({
  position: "relative",
  zIndex: 999,
  width: 36,
  height: 36,
  flex: "0 0 36px",
  fontSize: 18,
  "&:hover, &:focus, &:focus-visible": {
    backgroundColor: theme.colors.fg,
    color: theme.colors.bg,
  },
  "&::before, &::after": {
    display: "none",
  },
}));

const SLink = styled(Link)({
  textDecoration: "none",
  lineHeight: 1.2,
  marginLeft: 10,
  "&:hover": {
    textDecoration: "underline",
  },
});

export { SiteHeader };
