import * as React from "react";
import styled from "@emotion/styled";
import { type To } from "react-router-dom";
import {
  Button,
  Container,
  FadeIn,
  FlexRow,
  IconMegaphone,
  IconX,
  Link,
} from "atoms";

type Announcement = {
  /**
   * Unique identifier for each announcement, can be simple incremented integer
   */
  id: number;
  /**
   * Main content of the announcement
   */
  message: React.ReactNode;
  /**
   * Optional link to show after message
   */
  link?: {
    text: React.ReactNode;
    to?: To;
    href?: string;
  };
  /**
   * Determines if the alert is able to be dismissed
   * Default: TRUE
   */
  isDismissible?: boolean;
  /**
   * Optional time to expire marked as read cookie, in days
   */
  repeatsEvery?: number;
};

// January 11, 2024 - Start IDs After 10
const currentAnnouncements: Announcement[] = [
  {
    id: 10,
    message: (
      <span>
        <IconMegaphone style={{ marginRight: 3 }} /> Win a free iPad Pro or a
        trip to NYC or Miami simply by inviting artists&nbsp;to&nbsp;HUG!
      </span>
    ),
    link: { text: "Get your invite link", to: "/artist-invites" },
    repeatsEvery: 14,
  },
];

/**
 * Local storage key for comma-separated list of announcement ids marked as read
 */
const ANNOUNCEMENT_KEY = "HUG_announcements_read";
const LIST_SEPARATOR = ",";
const ITEM_SEPARATOR = "_";

/**
 * Parsed value from local storage of announcement ids marked as read with optional expiration date
 */
type AnnouncementRead = {
  id: Announcement["id"];
  expires?: Date;
};

/**
 * Save array of announcements read in local storage
 */
const saveAnnouncementsRead = (read: AnnouncementRead[]) => {
  window.localStorage.setItem(
    ANNOUNCEMENT_KEY,
    read
      .map(({ id, expires }) =>
        expires ? `${id}${ITEM_SEPARATOR}${expires.toISOString()}` : id,
      )
      .join(LIST_SEPARATOR),
  );
};

/**
 * Get array of announcements marked as read
 *
 * This has a side effect of removing any repeated announcements as unread if expired.
 */
const getAnnouncementsRead = (): AnnouncementRead[] => {
  // Get comma-separated list of announcement ids
  const value = window.localStorage.getItem(ANNOUNCEMENT_KEY);

  // If value doesn't exist, no announcements have been marked as read
  if (!value) {
    return [];
  }

  // Split list into array, split each id-expiration string into parts, filter out any that have expired
  const remaining = value
    .split(LIST_SEPARATOR)
    .map((str): AnnouncementRead => {
      const parts = str.split(ITEM_SEPARATOR);
      return {
        id: Number(parts[0]),
        expires: parts[1] ? new Date(parts[1]) : undefined,
      };
    })
    .filter(({ expires }) => !expires || expires > new Date());

  // Reset value in storage if any expired ids have been filtered out
  saveAnnouncementsRead(remaining);

  return remaining;
};

function AnnouncementList() {
  const [announcementsRead, setAnnouncementsRead] = React.useState(() =>
    getAnnouncementsRead(),
  );

  /**
   * Mark an announcement read by id
   */
  const markAsRead = (id: number) => {
    // Get list of current announcement ids
    const currentIds = currentAnnouncements.map((cur) => cur.id);
    // Filter out any old values of deleted announcements from prior storage
    const filtered = announcementsRead.filter((old) =>
      currentIds.includes(old.id),
    );

    // If this announcement repeats, set the expiration date
    let expires: Date | undefined;
    const repeatsEvery = currentAnnouncements.find(
      (cur) => cur.id === id,
    )?.repeatsEvery;
    if (repeatsEvery) {
      expires = new Date();
      expires.setDate(expires.getDate() + repeatsEvery);
    }

    // Append new announcement to be marked as read
    const newValue = [...filtered, { id, expires }];

    // Store the ids as a comma-separated string in storage
    saveAnnouncementsRead(newValue);
    // And update local state
    setAnnouncementsRead(newValue);
  };

  // Make sure each current announcement hasn't been read and shouldn't be excluded
  const visibleAnnouncements = React.useMemo(() => {
    const readIds = announcementsRead.map(({ id }) => id);
    return currentAnnouncements.filter(({ id }) => !readIds.includes(id));
  }, [announcementsRead]);

  return (
    <AnnouncementListWrapper>
      <SContainer width="lg">
        {visibleAnnouncements.map((announcement) => (
          <AnnouncementElement
            key={announcement.id}
            {...announcement}
            markAsRead={markAsRead}
          />
        ))}
      </SContainer>
    </AnnouncementListWrapper>
  );
}

const dismissDuration = 500;

type AnnouncementElementProps = Omit<Announcement, "exclude"> & {
  markAsRead: (id: number) => void;
};

function AnnouncementElement({
  id,
  link,
  markAsRead,
  message,
  isDismissible = true,
  ...props
}: AnnouncementElementProps) {
  const [isHidden, setIsHidden] = React.useState(false);

  const hideAnnouncement = () => {
    // Start animation to hide element
    setIsHidden(true);
    // Then remove it from the list after timeout
    setTimeout(() => {
      markAsRead(id);
    }, dismissDuration);
  };

  const internalLink = link?.to;
  const externalLink = link?.href;

  return (
    <AnnouncementMask isHidden={isHidden}>
      <AnnouncementWrapper isDismissible={isDismissible}>
        <AnnouncementContent {...props}>
          {message}

          {internalLink && (
            <AnnouncementButton
              variant="primary"
              size="xxs"
              to={link.to}
              onClick={hideAnnouncement}
            >
              {link.text}
            </AnnouncementButton>
          )}
          {externalLink && (
            <AnnouncementButton
              variant="primary"
              size="xxs"
              href={link.href}
              target="_blank"
              onClick={hideAnnouncement}
            >
              {link.text}
            </AnnouncementButton>
          )}
        </AnnouncementContent>

        {isDismissible && (
          <Dismiss aria-label="close" onClick={hideAnnouncement}>
            <IconX />
          </Dismiss>
        )}
      </AnnouncementWrapper>
    </AnnouncementMask>
  );
}

const SContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.maxDesktop]: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
}));

const AnnouncementListWrapper = styled(FadeIn)({
  position: "relative",
  zIndex: 3,
});

const lineHeight = "1.2rem";
const vPadding = "10px";

const AnnouncementMask = styled.div<{
  isHidden: boolean;
}>(({ isHidden, theme }) => ({
  marginTop: 10,
  marginBottom: 3,
  maxHeight: 200,
  opacity: 1,
  overflow: "hidden",
  transition: `${dismissDuration}ms ease all`,
  [theme.breakpoints.tablet]: {
    maxHeight: "5rem",
    ...(isHidden && {
      maxHeight: 0,
    }),
  },
  ...(isHidden && {
    marginTop: 0,
    marginBottom: 0,
    maxHeight: 0,
    opacity: 0,
  }),
}));

const AnnouncementWrapper = styled.div<{ isDismissible: boolean }>(
  ({ isDismissible, theme }) => ({
    borderRadius: theme.borderRadius.sm,
    backgroundColor: theme.colors.accent2,
    display: "flex",
    justifyContent: "center",
    color: theme.colors.bg,
    fontSize: theme.fontSizes.xs,
    paddingRight: isDismissible ? "3rem" : "1rem",
    paddingLeft: "1rem",
    position: "relative",
  }),
);

const AnnouncementContent = styled(FlexRow)({
  lineHeight,
  paddingTop: vPadding,
  paddingBottom: vPadding,
  position: "relative",
  gap: 6,
});

const AnnouncementButton = styled(Link)({
  backgroundColor: "rgb(0, 0, 0, .33)",
  border: 0,
  flex: "0 0 auto",
  padding: "4px 1em 3px 1em",
  position: "static",
  "&::before": {
    content: "''",
    position: "absolute",
    inset: 0,
  },
});

const Dismiss = styled(Button)(({ theme }) => ({
  border: 0,
  color: theme.colors.white,
  opacity: 0.7,
  justifySelf: "flex-end",
  flex: "0 0 auto",
  position: "absolute",
  margin: 0,
  top: 0,
  right: 0,
  height: `calc((${vPadding} * 2) + ${lineHeight})`,
  width: `calc((${vPadding} * 2) + ${lineHeight})`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover, &:focus-visible": {
    opacity: 1,
    color: theme.colors.white,
  },
}));

Dismiss.defaultProps = {
  size: "xxs",
  variant: "blank",
};

export { AnnouncementElement, AnnouncementList, type Announcement };
